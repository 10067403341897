<template>
  <div class="modify">
      <div class="modify-center">
          <p>修改手机号</p>
          <div class="modify-container">
              <div class="modify-list">
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
                         <el-form-item prop="phone">
                            <p class="form-text">手机号</p>
                            <el-input v-model="ruleForm.phone" auto-complete="off" placeholder="请输入您的手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="smscode">
                            <p class="form-text">验证码</p>
                            <el-input v-model="ruleForm.smscode" placeholder="请输入验证码" style="width:70%;"></el-input>
                            <el-button class="button-code" :disabled='isDisabled' @click="sendCode">{{buttonText}}</el-button>
                        </el-form-item>
                        <el-form-item style="margin-bottom:0;">
                            <el-button ype="primary" @click="submitForm('ruleForm')" class="login-button">确认修改</el-button>
                        </el-form-item>
                    </el-form>
              </div>
          </div>
      </div>
  </div>
</template>

<script> 
import { changePhone } from "../../api/personal"
import { sendCode } from "../../api/register"
export default {
    data(){
        // 验证手机号是否合法
        let checkTel = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码"));
            } else if (!this.checkMobile(value)) {
                callback(new Error("手机号码格式不正确"));
            } else {
                callback();
            }
        }
        // 验证码是否为空
        let checkSmscode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机验证码'))
            } else {
                callback()
            }
        }
        return{
            ruleForm: {
                phone: "",
                smscode: ""
            },
            rules: {
                phone: [{ validator: checkTel, trigger: 'change' }],
                smscode: [{ validator: checkSmscode, trigger: 'change' }]
            },
            buttonText: '获取验证码',
            isDisabled: false, // 是否禁止点击发送验证码按钮
            flag: true,
        }
    },
    methods:{
        // 发送验证码
        sendCode () {
            let phone = this.ruleForm.phone
            // console.log(phone)
            if (this.checkMobile(phone)) {
                sendCode({phone:phone}).then(res => {
                    this.$message.success(res.data.message)
                    let time = 60
                    this.buttonText = '已发送'
                    this.isDisabled = true
                    if (this.flag) {
                        this.flag = false;
                        let timer = setInterval(() => {
                            time--;
                            this.buttonText = time + ' 秒'
                            if (time === 0) {
                                clearInterval(timer);
                                this.buttonText = '重新获取'
                                this.isDisabled = false
                                this.flag = true;
                            }
                        }, 1000)
                    }
                });
            }
        },

        //确认修改
        submitForm(formName){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    // this.$router.go(-1)
                    changePhone({
                        phone:this.ruleForm.phone,
                        code:this.ruleForm.smscode
                    }).then(res=>{
                        if(res.data.code == 20000){
                            this.$message.success(res.data.message)
                            this.$router.push("/login");
                            this.$store.commit('del_token')
                            window.localStorage.clear(); 
                        }else{
                            this.$message.error(res.data.message);
                        } 
                    })
                }
            })
        },

         // 验证手机号
        checkMobile(str) {
            let re = /^1\d{10}$/;
            if (re.test(str)) {
                return true;
            } else {
                return false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.modify{
    width:100%;
    height:592px;
    background-color: #ffffff;
    padding:30px;
    .modify-center{
        width:100%;
        p{
            color:#000000;
            font-size:20px;
            font-weight:500;
        }
        .modify-container{
            width:420px;
            margin-top:24px;
            .modify-list{
                .form-text{
                    color:#333333;
                    font-size:12px;
                }
                /deep/ .el-button{
                    margin-left:10px;
                    background-color: #123E90;
                    color: #ffffff;
                    font-size: 14px;
                }
                .login-button{
                    width:92px;
                    height:36px;
                    background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
                    border-radius:6px;
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    color:#FFFFFF;
                    font-size:14px;
                    border:none;
                    margin-left:0;
                    margin-top:15px;
                }
            }
        }
    }
}
</style>